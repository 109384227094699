

import http from "@/utils/request";

export function getUsers(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getUsers.nd",
        data
    });
}

export function getztUsers(data) {
    return http({
        method: "post",
        url: "/distributeBoothReport/findOfficeManager.nd",
        data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}
