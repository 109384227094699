import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import cancelreasonModal from "./../components/cancelReason.vue";
import {
    apply,
    getCity,
    getArea,
    getMarketCenter,
    getStreet,
    save,
    dimission,
    getParams,
    getMerchant,
    getMerchantDetail,
    getBsc,
    getPrincipal,
    getPrincipalDetail,
    getSupplier,
    getWlz,
    getStore,
    getSalesman,
    getMarketingCenter,
    getOperator,
    getDetail
} from "./api";
import { publicPath } from "@/common/constant.js";
export default {
    name: "index",
    components: {
        BaiduMap,
        cancelreasonModal
    },
    data() {
        return {
            uploadUrl: publicPath + "/comm/method=uploadFile.nd",
            type: 'edit', // add edit
            customer: '',
            visible: false,
            bscManagerId: '', // 办事处经理id
            content: '', // 申请原因
            mapShow: false,
            center: '',
            zoom: 15,
            BMap: null,
            // 面包屑数据
            breadcrumbData: [
                {
                    path: "/index",
                    name: "index",
                    title: "首页",
                },
                {
                    path: "/basicInfo?type=7",
                    name: "basicInfo?type=7",
                    title: "分销的门店",
                },
                {
                    path: "/distributionStores/checkDistributionStore",
                    name: "checkDistributionStore",
                    title: "查看门店",
                },
            ],

            spinning: false, //页面整体loading
            editCount: 0,//表单编辑次数 >1则有编辑
            menuList: [
                {
                    title: "门店基础信息",
                    active: true,
                },
                {
                    title: "办事处视图",
                    active: false,
                },
                {
                    title: "门店销售规模",
                    active: false,
                },
            ],
            isFixed: false, //左侧是否跟随
            formData: {},
            //表单验证
            rules: {
                ownedBusiness: [
                    {
                        required: true,
                        message: "请选择所属商家",
                        trigger: ["change", "blur"],
                    },
                ],
                marketLevel: [{
                    required: true,
                    message: "请选择市场级别",
                    trigger: ["change", "blur"],
                }],
                storeFullName: [{
                    required: true,
                    message: "请输入门店全称",
                    trigger: ["change", "blur"],
                }],
                storeShortName: [{
                    required: true,
                    message: "请输入门店简称",
                    trigger: ["change", "blur"],
                }],
                province: [{
                    required: true,
                    message: "请选择省",
                    trigger: ["change", "blur"],
                }],
                city: [{
                    required: true,
                    message: "请选择市",
                    trigger: ["change", "blur"],
                }],
                area: [{
                    required: true,
                    message: "请选择区县",
                    trigger: ["change", "blur"],
                }],
                street: [{
                    required: true,
                    message: "请选择乡镇街道",
                    trigger: ["change", "blur"],
                }],
                // marketLv: [{
                //     required: true,
                //     message: "请选择市场级别",
                //     trigger: ["change", "blur"],
                // }],
                address: [{
                    required: true,
                    message: "请选择门店地址",
                    trigger: ["change", "blur"],
                }],
                postCode: [{
                    required: true,
                    message: "请输入邮政编码",
                    trigger: ["change", "blur"],
                }, {
                    validator: this.isPostCode,
                    message: "请输入正确的邮政编码",
                    trigger: ["change", "blur"],
                }],
                cityLv: [{
                    required: true,
                    message: "请选择城市级别",
                    trigger: ["change", "blur"],
                }],
                storefront: [{
                    required: true,
                    message: "请选择店面性质",
                    trigger: ["change", "blur"],
                }],
                isSink: [{
                    required: true,
                    message: "请选择是否下沉渠道",
                    trigger: ["change", "blur"],
                }],
                storeCategory: [{
                    required: true,
                    message: "请选择门店类别",
                    trigger: ["change", "blur"],
                }],
                isCore: [{
                    required: true,
                    message: "请选择是否核心门店",
                    trigger: ["change", "blur"],
                }],
                principal: [{
                    required: true,
                    message: "请选择片区负责人",
                    trigger: ["change", "blur"],
                }],
                isBooth: [{
                    required: true,
                    message: "请选择是否做展台",
                    trigger: ["change", "blur"],
                }],
                content: [{
                    required: true,
                    message: "请输入申请原因",
                    trigger: ["change", "blur"],
                }],
            },
            scrollWatch: true,//滚动flag
            info: '',
            storeDemandIdx: [],
            storeDemand: [
                {
                    title: "需求子单号",
                    dataIndex: "orderNo",
                    width: 150,
                },
                {
                    title: "物料组",
                    dataIndex: "wlz",
                    width: 150,
                    scopedSlots: { customRender: 'storeDemand' },
                },
                {
                    title: "所属办事处",
                    dataIndex: "bsc",
                    width: 120,
                },
                {
                    title: "需求类型",
                    dataIndex: "demandType",
                    width: 120,
                    scopedSlots: { customRender: 'demandType' },
                },
                {
                    title: "派单类型",
                    dataIndex: "dispatchType",
                    width: 120,
                    scopedSlots: { customRender: 'dispatchType' },
                },
                {
                    title: "供应商",
                    dataIndex: "supplier",
                    width: 120,
                    scopedSlots: { customRender: 'supplier' },
                },
                {
                    title: "费用预估(元)",
                    dataIndex: "costEstimate",
                    width: 120,
                    scopedSlots: { customRender: 'costEstimate' },
                },
                {
                    title: "要求完成时间",
                    dataIndex: "time",
                    width: 120,
                    scopedSlots: { customRender: 'time' },
                },
                {
                    title: "说明",
                    dataIndex: "illustrate",
                    width: 120,
                    scopedSlots: { customRender: 'illustrate' },
                },
            ],// 门店需求
            storeDemands: [],// 门店需求
            selectedRowKeys: [],
            selectedRowKeyscypl: [],
            bscIdx: [], // 办事处下标
            bsc: [
                {
                    title: "所属办事处",
                    dataIndex: "bsc",
                    width: 200,
                    scopedSlots: { customRender: "bsc" }
                },
                {
                    title: "分公司名称",
                    dataIndex: "company",
                    width: 200,
                    scopedSlots: { customRender: "" }
                },
                {
                    title: "品牌",
                    dataIndex: "category",
                    width: 200,
                    scopedSlots: { customRender: "category" }
                },
                {
                    title: "物料组",
                    dataIndex: "wlz",
                    width: 200,
                    scopedSlots: { customRender: "wlz" }
                },
                {
                    title: "是否主销楼层",
                    dataIndex: "isMainFloor",
                    width: 200,
                    scopedSlots: { customRender: "isMainFloor" }
                },
                {
                    title: "位置排名",
                    dataIndex: "locationRanking",
                    width: 200,
                    scopedSlots: { customRender: "locationRanking" }
                },
                {
                    title: "紧临品牌",
                    dataIndex: "closedBrand",
                    width: 200,
                    scopedSlots: { customRender: "closedBrand" }
                },
                {
                    title: "计划配置促销员人数",
                    dataIndex: "peopleNum",
                    width: 200,
                    scopedSlots: { customRender: "peopleNum" }
                },
                {
                    title: "促销员编码/姓名",
                    dataIndex: "promoter",
                    width: 200,
                    scopedSlots: { customRender: "promoter" }
                },
                {
                    title: "门店级别",
                    dataIndex: "storeLevel",
                    width: 200,
                    scopedSlots: { customRender: "storeLevel" }
                },
                {
                    title: "所属运营商",
                    dataIndex: "operator",
                    width: 200,
                    scopedSlots: { customRender: "operator" }
                },
                {
                    title: "是否品牌园",
                    dataIndex: "brandPark",
                    width: 200,
                    scopedSlots: { customRender: "" }
                },
                {
                    title: "所在楼层",
                    dataIndex: "floor",
                    width: 200,
                    scopedSlots: { customRender: "floor" }
                },
                {
                    title: "门店展厅面积",
                    dataIndex: "area",
                    width: 200,
                    scopedSlots: { customRender: "area" }
                },
                {
                    title: '展台延米(m)',
                    dataIndex: 'ztym',
                    width: 200,
                    scopedSlots: { customRender: "ztym" }
                },
                {
                    title: "样机位数量",
                    dataIndex: "num",
                    width: 200,
                    scopedSlots: { customRender: "num" }
                },
                {
                    title: "门店经理姓名",
                    dataIndex: "manager",
                    width: 200,
                    scopedSlots: { customRender: "manager" }
                },
                {
                    title: "门店经理电话",
                    dataIndex: "managerPhone",
                    width: 200,
                    scopedSlots: { customRender: "managerPhone" }
                },
                {
                    title: "营销员姓名/编码",
                    dataIndex: "salesman",
                    width: 200,
                    scopedSlots: { customRender: "salesman" }
                },
                {
                    title: "营销员电话",
                    dataIndex: "salesmanPhone",
                    width: 200,
                    scopedSlots: { customRender: "" }
                },
                {
                    title: "进驻时间",
                    dataIndex: "settlingTime",
                    width: 200,
                    scopedSlots: { customRender: "" }
                },
                {
                    title: "冻结时间",
                    dataIndex: "freezeTime",
                    width: 200,
                    scopedSlots: { customRender: "" }
                },
                {
                    title: "通信品牌信联",
                    dataIndex: "brand",
                    width: 200,
                    scopedSlots: { customRender: "brand" }
                },
                {
                    title: "是否发送SAP",
                    dataIndex: "isSap",
                    width: 200,
                    scopedSlots: { customRender: "isSap" }
                },
                {
                    title: "是否进驻",
                    dataIndex: "isEnter",
                    width: 200,
                    scopedSlots: { customRender: "isEnter" }
                },
                {
                    title: "是否TOP客户加盟店",
                    dataIndex: "isTop",
                    width: 200,
                    scopedSlots: { customRender: "isTop" }
                },
                // {
                //     title: "发货分公司",
                //     dataIndex: "shippingCompany",
                //     width: 200,
                //     scopedSlots: { customRender: "shippingCompany" }
                // },
                {
                    title: "补货商家",
                    dataIndex: "merchant",
                    width: 200,
                    // scopedSlots: { customRender: "merchant" }
                },
                {
                    title: "合作状态",
                    dataIndex: "cooperationStatus",
                    width: 200,
                    scopedSlots: { customRender: "cooperationStatus" }
                },
                {
                    title: "冻结凭证",
                    dataIndex: "freezeVouchers",
                    width: 200,
                    scopedSlots: { customRender: "freezeVouchers" }
                },
                {
                    title: "营销中心办事处",
                    dataIndex: "marketingCenter",
                    width: 200,
                    scopedSlots: { customRender: "marketingCenter" }
                },
            ],// 办事处
            bscList: [],
            bscInfos: [],//办事处信息
            xsgmIdx: [],
            xsgm: [
                {
                    title: "物料组",
                    dataIndex: "wlz",
                    width: 200,
                    scopedSlots: { customRender: "wlz" }
                },
                {
                    title: "年销售规模（万元）",
                    dataIndex: "nxsgm",
                    // width: 400,
                    scopedSlots: { customRender: "nxsgm" }
                },
                {
                    title: "预计门店规模（万元）",
                    dataIndex: "yjmdgm",
                    // width: 400,
                    scopedSlots: { customRender: "yjmdgm" }
                },

            ], //销售规模
            xsgmList: [],
            MarketTableList: [],
            tableData: [], // 物料组数据
            lctableData: [], // 临促数据
            TselectedRowKeys: [],
            lcselectedRowKeys: [],
            ownedBusiness: [], //所属商家列表
            marketLevels: [], //市场级别
            storeFullNames: [],//门店全称
            storeShortNames: [], //门店简称
            province: [],//省
            provinceStr: '',//
            city: [],//市
            cityStr: '',
            area: [],//区
            areaStr: '',
            street: [],//街道
            streetStr: '',
            // marketLv: [],//市场级别
            cityLv: [], //城市级别
            storefront: [], //店面性质
            storeCategory: [], //门店类别
            principal: [], //片区负责人
            isCore: [],//是否核心店铺
            isEnter: [], //是否入住
            djFlag: [], //冻结
            isSink: [],//是否下沉
            isBooth: [], //是否做展台
            isTop: [], //是否top客户加盟店
            storeLevel: [], //门店级别
            merchantDetail: '',//商家详情
            marketingCenter: '',//营销中心
            brand: [], //通信品牌信联
            isSap: [], //是否发送sap
            demandType: [], //需求类型
            dispatchType: [], //派单类型
            wlz: [], //
            salesman: [], //营销员
            marketingCenterBsc: [],
            operator: [], //所属运营商
            supplier: [], //供应商
            category: [],
            cooperationStatus: [],//合作状态
            bscWlz: [],//办事处物料组 code name bsc

            circleType: [], //商圈类型
            store3Size: [], //门店三大品类年规模
            brandSettled:[], //友商品牌进驻
            isMainFloor: [], //是否主销楼层
            closedBrand: [], // 紧临品牌
            promoter: [], //促销员
            isStandard: [],//是否达标
        };
    },
    watch: {},
    destroyed() {
        this.scrollWatch = false;
    },
    mounted() {
        $(window).on("scroll", this.handleScroll);
        this.$nextTick();
        this.customer = this.$store.state.user.userInfo.customer
        if (this.$route.query.type) {
            this.type = this.$route.query.type;
        }
        if (this.type == 'add') {
            this.formData = {
                znxsgm: 0, //总年销售规模(万元)：
                zyjmdgm: 0, // 总预计门店规模(万元)：
                isBooth: '', //是否做展台
                cis: '',
                mdm: '',
                ownedBusiness: '', //所属商家
                storeFullName: '',//门店全称
                storeShortName: '', //门店简称
                marketLevel: '', //市场级别
                province: '', //省
                city: '',
                area: '',
                street: '',
                // marketLv: '', //市场级别
                address: 'address',
                addrDescribe: '', //地址描述
                marketingCenter: '',//营销中心
                marketingCenterId: '',
                postCode: '', //邮编
                cityLv: '', //城市级别
                storefront: '', //店面性质
                isSink: '', //是否下沉渠道
                storeCategory: [], //门店类别
                from: '信天翁', //来源
                channelCategory: '', //渠道类别
                channelCategoryId: '',
                channelCategorySub: '', //渠道类别细分
                channelCategorySubId: '',
                labelLv1: '', //集团一级标签
                labelLv1Id: '',
                labelLv2: '', //集团二级标签
                labelLv2Id: '',
                isCore: '', //是否核心门店
                principal: '',// 片区负责人
                principalPhone: '',//片区负责人电话
                headquartersMarketCategory: '',//总部市场类别
                projectRequirements: '',//项目需求名称：
                planProject: '展台制作费-非专卖店', //计划项目名称：
                content: '',//申请原因
            }
            this.getParams();
        }
        if (this.type == 'edit') {
            apply().then(res => {
                this.province = res.data.provicesList;
                this.getDetail();
            });
        }

    },
    methods: {
        getDetail() {
            getDetail(this.$route.query.id).then(res => {
                let info = res.data.data
                let znxsgm = 0;
                let zyjmdgm = 0;
                let xsgmList = []
                if (info.custShopInfoSalesCheckList) {
                    for (let item of info.custShopInfoSalesCheckList) {
                        znxsgm = this.accAdd(znxsgm,item.annualSales)
                        zyjmdgm = this.accAdd(zyjmdgm,item.forcastSales)
                        xsgmList.push({
                            id: item.id,
                            isNewRow: false,
                            wlz: item.matklId + '',
                            nxsgm: item.annualSales,
                            yjmdgm: item.forcastSales
                        })
                    }
                    this.xsgmList = xsgmList
                }

                let storeDemands = []
                if (info.supplyDetailList) {
                    for (let item of info.supplyDetailList) {
                        storeDemands.push({
                            orderNo: item.supplyDetailCode,
                            id: item.id,
                            isNewRow: false,
                            wlz: item.baseMatklId, //物料组
                            wlzName: item.baseMatklName,
                            bsc: item.custShopInfoDetailCheckDto.orgName,//所属办事处
                            demandType: item.supplyTypeId + '',//需求类型
                            dispatchType: item.isHeaderUnion + '',//派单类型
                            supplier: item.supplierCode,//供应商
                            supplierName: item.supplierName,
                            costEstimate: item.estimatedCost,//费用预估
                            time: item.finishDate,//要求完成时间
                            illustrate: item.remark, //说明
                        })
                    }
                    this.storeDemands = storeDemands;
                }
                if(info.isUpStandard == 1) {
                    info.isUpStandard = '是'
                }
                if(info.isUpStandard == 0) {
                    info.isUpStandard = '否'
                }
                this.formData = {
                    znxsgm, //总年销售规模(万元)：
                    zyjmdgm, // 总预计门店规模(万元)：
                    isBooth: info.isSubmitSupplyId + '', //是否做展台
                    isBoothText: info.isSubmitSupplyText, //是否做展台
                    cis: info.cisCode,
                    mdm: info.mdmCode,
                    ownedBusiness: info.custInfoId, //所属商家
                    ownedBusinessText: info.custInfoId_name, //所属商家
                    storeFullName: info.fullName,//门店全称
                    storeShortName: info.searchTerm, //门店简称
                    marketLevel: info.marketLevelId + '', //市场级别
                    address: info.mapAddress,
                    addrDescribe: info.addressDesc, //地址描述
                    marketingCenter: info.marketCenterText,//营销中心
                    marketingCenterId: info.marketCenterId,
                    postCode: info.postalCode, //邮编
                    cityLv: info.districtType, //城市级别
                    storefront: info.shopPropertyId + '', //店面性质
                    isSink: info.isSinkChannel, //是否下沉渠道
                    storeCategory: info.shopCategory, //门店类别
                    from: '信天翁', //来源
                    isTobeHxStore: info.isPlanSpecial != null ?info.isPlanSpecial + '' :'',
                    channelCategory: '', //渠道类别
                    channelCategoryId: '',
                    channelCategorySub: '', //渠道类别细分
                    channelCategorySubId: '',
                    labelLv1: '', //集团一级标签
                    labelLv1Id: '',
                    labelLv2: '', //集团二级标签
                    labelLv2Id: '',
                    isCore: info.isCoreShop, //是否核心门店 == 1?'是' :'否'
                    principalPhone: info.areaLeaderId,//片区负责人电话
                    headquartersMarketCategory: '',//总部市场类别
                    projectRequirements: info.projectSupplyName,//项目需求名称：
                    planProject: '展台制作费-非专卖店', //计划项目名称：
                    content: info.content, // 申请原因
                    circleType: info.tradingTypeId + '', //商圈类型
                    store3Size: info.threeScaleId + '', //门店三大品类年规模
                    brandSettled: info.friendBrandId, //友商品牌进驻
                    isStandard: info.isUpStandard,//是否达标
                    standardDescribe: info.noStandardReason,//达标描述
                }
                getMerchantDetail(info.custInfoId).then(res => {
                    let merchantDetail = res.data.data;
                    this.formData.channelCategory = merchantDetail.primaryChannelText;
                    this.formData.channelCategoryId = merchantDetail.primaryChannelId;

                    this.formData.channelCategorySub = merchantDetail.secondChannelText;
                    this.formData.channelCategorySubId = merchantDetail.secondChannelId;

                    this.formData.labelLv1 = merchantDetail.primaryLabelText;
                    this.formData.labelLv1Id = merchantDetail.primaryLabelId;

                    this.formData.labelLv2 = merchantDetail.secondLabelText;
                    this.formData.labelLv2Id = merchantDetail.secondLabelId;
                })
                getBsc(this.formData.ownedBusiness).then(res => {
                    this.bscInfos = res.data.list;
                    let bscList = [];
                    let bscWlz = []
                    if(info.shopInfoDetailCheckList) {
                        for (let item of info.shopInfoDetailCheckList) {
                            bscWlz.push({
                                bsc: item.orgId,
                                bscName: item.orgName,
                                code: item.baseMatklId + '',
                                name: item.baseMatklName
                            })
                            let data = {
                                id: item.id,
                                isNewRow: false,
                                bsc: item.orgId, //所属办事处
                                bscId: item.orgId, //所属办事处
                                bscName: item.orgName, //所属办事处
                                company: item.fgsName, //分公司名称
                                companyId: item.orgId, //分公司名称
                                category: item.brandId && item.brandId + '', //品类
                                wlz: item.baseMatklId + '',//物料组
                                wlzName: item.baseMatklName,
                                storeLevel: item.shopLevelId + '',//门店级别
                                operator: item.runCustTypeName,//所属运营商
                                operatorId: item.runCustTypeId,//所属运营商
                                brandPark: item.isBrandGardenText, //是否品牌园
                                floor: item.shopFloor,// 所在楼层
                                area: item.shopArea,//门店展厅面积
                                ztym: item.singleMeter, //展台延米
                                num: item.modelCount, //样机位数量
                                manager: item.managerName,//门店经理姓名
                                managerPhone: item.managerTel,//门店经理电话
                                salesman: item.sellerId,//营销员姓名/编码
                                salesmanText: item.sellerName,
                                salesmanPhone: item.sellerTel,//营销员电话
                                settlingTime: item.enterTime, //进驻时间
                                freezeTime: item.djTime,//冻结时间
                                brand: item.netStandardId && item.netStandardId + '',//通信品牌信联
                                isSap: item.isToSap,//是否发送SAP
                                isEnter: item.isEnter,
                                isTop: item.isTopShop,
                                // shippingCompany: '',//发货分公司
                                merchant: item.warehouseName,//补货商家
                                merchantId: item.warehouseId,//补货商家
                                cooperationStatus: item.djFlag,//合作状态
                                freezeVouchers: item.djVouchers,//冻结凭证
                                marketingCenter: item.terminalOrgId && item.terminalOrgId + '',//营销中心办事处
                                file: [],
                                isMainFloor: item.isMainFloor, //是否主销楼层
                                locationRanking: item.placeRank, //位置排名
                                closedBrand: item.closedBrandId + '', // 紧临品牌
                                peopleNum: item.cxPeopleCount, //计划配置促销员人数
                                promoter: item.cxPeopleId + '', //促销员
                                promoterName: item.cxPeopleName,
                            }
                            if(item.djVouchers[0]) {
                                data.file = [{
                                    name: item.djVouchers[0].attachShortName,
                                    id: item.djVouchers[0].id
                                }]
                            }
                            bscList.push(data)
                        }
                        this.bscList = bscList;
                        this.bscWlz = bscWlz;
                        if (info.shopInfoDetailCheckList[0]) {
                            getWlz(this.customer.id, info.shopInfoDetailCheckList[0].orgId).then(res => {
                                this.wlz = res.data.list
                            })
                            getMarketingCenter(info.shopInfoDetailCheckList[0].orgId).then(res => {
                                this.marketingCenterBsc = res.data.list
                            })
                            // data = {
                            //     searchstr: '',
                            //     officeId: info.shopInfoDetailCheckList[0].orgId
                            // }
                            // getSalesman(data).then(res => {
                            //     this.salesman = res.data.list
                            // })
                        }
                    }
                    
                })
                


                this.getParams();
                let data = {
                    proviceId: info.provinceId
                };
                getCity(data).then(res => {
                    this.city = res.data;
                })
                data = {
                    cityId: info.cityId
                };
                getArea(data).then(res => {
                    this.area = res.data;
                })
                data = {
                    districtId: info.countyId
                };
                getStreet(data).then(res => {
                    this.street = res.data;
                })
                this.formData.province = info.provinceId
                this.formData.city = info.cityId
                this.formData.area = info.countyId
                this.formData.street = info.townId
                data = {
                    searchstr: ''
                }
                getPrincipal(data).then(res => {
                    if (res.data.code == 0) {
                        this.principal = res.data.list;
                        this.formData.principal = info.areaLeaderId
                    }
                })
            })
        },
        checkMap() {
            if (!this.provinceStr) {
                this.$message.info("请选择省份", 2);
                return
            }
            if (!this.cityStr) {
                this.$message.info("请选择城市", 2);
                return
            }
            if (!this.areaStr) {
                this.$message.info("请选择区县", 2);
                return
            }
            if (!this.streetStr) {
                this.$message.info("请选择乡镇街道", 2);
                return
            }
            this.center = this.provinceStr + this.cityStr + this.areaStr + this.streetStr
            console.log('center', this.center)
            this.mapShow = true;
        },
        mapReady() {
            this.zoom = 15;
            this.BMap = BMap;
        },
        mapTap(e) {
            this.center = {
                lng: e.Bg.lng,
                lat: e.Bg.lat
            };
            const myGeo = new this.BMap.Geocoder();
            myGeo.getLocation(
                new this.BMap.Point(this.center.lng, this.center.lat),
                result => {
                    if (result) {
                        // address: "山东省青岛市市南区镇江路101"
                        console.log(result.address);
                        this.formData.address = result.address;
                    }
                }
            );
        },
        isPostCode(e, v) {
            return /^[0-9]{6}$/.test(Number(v))
        },
        //左侧导航点击
        navClickFun(index, isScroll) {
            for (const idx in this.menuList) {
                if (idx == index) {
                    if (!isScroll) {
                        $("body,html").animate(
                            {
                                scrollTop: $("#type" + index).offset().top,
                            }, 0
                        );
                    }
                    //活动状态
                    let timer = setTimeout(() => {
                        for (const idx in this.menuList) {
                            this.menuList[idx].active = false;
                        }
                        this.menuList[index].active = true;
                        clearTimeout(timer);
                    }, 1);
                    return;
                }
            }
        },

        //左侧导航跟随滚动
        navScrollFun(index, isScroll) {
            for (const idx in this.menuList) {
                this.menuList[idx].active = false;
            }
            for (const idx in this.menuList) {
                if (idx == index) {
                    this.menuList[index].active = true;
                    return;
                }
            }
        },
        //判断详情通栏是否吸附
        handleScroll() {
            if (!this.scrollWatch) {
                return
            }
            if ($("#boxFixedRefer").offset() && $("#boxFixedRefer").offset().top) {
                let scrollTop =
                    $("#boxFixedRefer").offset().top - $(window).scrollTop();
                if (scrollTop <= 0) {
                    this.isFixed = true;
                } else {
                    this.isFixed = false;
                }
            }
            //滚动-》左侧交互
            if ($("#type2").offset().top - $(window).scrollTop() < 40) {
                this.navScrollFun(2, true);
            } else if ($("#type1").offset().top - $(window).scrollTop() < 40) {
                this.navScrollFun(1, true);
            } else if ($("#type0").offset().top - $(window).scrollTop() < 40) {
                this.navScrollFun(0, true);
            }
        },

        //左侧点击交互
        navJumpFun(index) {
            for (const idx in this.menuList) {
                this.menuList[idx].active = false;
            }
            this.menuList[index].active = true;
        },


        provinceChange(e, item) {
            console.log(this.province[item.data.key].provinceName)
            this.provinceStr = this.province[item.data.key].provinceName;
            this.formData.city = '';
            this.formData.area = '';
            this.formData.street = '';
            this.city = []
            this.area = []
            this.street = []
            let data = {
                proviceId: e
            };
            getCity(data).then(res => {
                this.city = res.data;
            })
        },
        cityChange(e, item) {
            console.log(this.city[item.data.key].cityName)
            this.cityStr = this.city[item.data.key].cityName
            this.area = []
            this.street = []
            let data = {
                cityId: e
            };
            getArea(data).then(res => {
                this.area = res.data;
            })
        },
        areaChange(e, item) {
            console.log(this.area[item.data.key].districtName)
            this.areaStr = this.area[item.data.key].districtName
            this.street = []
            let data = {
                districtId: e
            };
            getStreet(data).then(res => {
                this.street = res.data;
            })
            // 根据区县查询营销中心
            getMarketCenter(e).then(res => {
                if (res.data.code == 0 && res.data.list[0]) {
                    this.marketingCenter = res.data.list[0]
                    this.formData.marketingCenter = res.data.list[0].name
                    this.formData.marketingCenterId = res.data.list[0].code
                }
            })
        },
        streetChange(e, item) {
            this.streetStr = this.street[item.data.key].townName
            console.log(this.street[item.data.key].townName)
        },
        // 返回
        goback() {
            this.$confirm({
                title: '本次修改该尚未保存，是否确认离开',
                onOk() {
                    window.close();
                },
                onCancel() { },
            })
        },


        //是否根据输入项进行筛选
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        // 选择门店需求同步提报
        storeDemandSelect(idx) {
            this.storeDemandIdx = idx;
        },
        // 添加门店需求同步提报
        addStoreDemand() {
            this.storeDemands.push({
                orderNo: '',//需求子单号
                wlz: '', //物料组
                bsc: '',//所属办事处
                demandType: '',//需求类型
                dispatchType: '',//派单类型
                supplier: '',//供应商
                costEstimate: '',//费用预估
                time: '',//要求完成时间
                illustrate: '', //说明
                category: [], //品牌
            })
        },
        // 删除门店需求同步提报
        delStoreDemand() {
            if (this.storeDemandIdx.length <= 0) {
                this.$message.warning('请至少选择一项数据!')
                return
            }
            this.storeDemands = this.storeDemands.filter((item, index) => !this.storeDemandIdx.includes(index))
            this.storeDemandIdx = []

        },
        // 复制门店需求同步提报
        copyStoreDemand() {
            if (this.storeDemandIdx.length <= 0) {
                this.$message.warning('请至少选择一项数据!')
                return
            }
            if (this.storeDemandIdx.length > 1) {
                this.$message.warning('只能一条数据进行复制!')
                return
            }
            let obj = this.storeDemands[this.storeDemandIdx[0]];
            this.storeDemands.push(JSON.parse(JSON.stringify(obj)))
        },
        // 办事处列表选择
        bscSelect(idx) {
            this.bscIdx = idx;
        },
        // 添加办事处
        addBsc() {
            if (!this.formData.ownedBusiness) {
                this.$message.info("请选择所属商家", 2);
                return
            }
            this.bscList.push({
                bsc: '', //所属办事处
                bscId: '', //所属办事处
                company: '', //分公司名称
                companyId: '', //分公司名称
                category: '', //品类
                wlz: '',//物料组
                storeLevel: '',//门店级别
                operator: '',//所属运营商
                operatorId: '',
                brandPark: '', //是否品牌园
                floor: '',// 所在楼层
                area: '',//门店展厅面积
                num: '', //样机位数量
                manager: '',//门店经理姓名
                managerPhone: '',//门店经理电话
                salesman: '',//营销员姓名/编码
                salesmanPhone: '',//营销员电话
                settlingTime: '', //进驻时间
                freezeTime: '',//冻结时间
                brand: '',//通信品牌信联
                isSap: '',//是否发送SAP
                isEnter: '',
                isTop: '',
                // shippingCompany: '',//发货分公司
                merchant: this.customer.customerName,//补货商家
                merchantId: this.customer.id,//补货商家
                cooperationStatus: '',//合作状态
                freezeVouchers: '',//冻结凭证
                marketingCenter: '',//营销中心办事处
                file: [],
                ztym: ''
                // idx: this.bscList.length
            });
        },
        // 删除办事处
        delBsc() {
            if (this.bscIdx.length <= 0) {
                this.$message.warning('请选择一项数据!')
                return
            }
            if (this.bscIdx.length > 1) {
                this.$message.warning('只能一条数据进行删除!')
                return
            }
            let obj = this.bscList[this.bscIdx[0]];
            if (obj.isNewRow) {
                this.bscList = this.bscList.filter((item, index) => !this.bscIdx.includes(index))
                this.bscIdx = []
            } else {
                this.$message.warning('已提交保存的数据不能删除')
            }

        },
        // 复制办事处
        copyBsc() {
            if (this.bscIdx.length <= 0) {
                this.$message.warning('请选择一项数据!')
                return
            }
            if (this.bscIdx.length > 1) {
                this.$message.warning('只能一条数据进行复制!')
                return
            }
            let obj = this.bscList[this.bscIdx[0]];
            this.bscList.push(JSON.parse(JSON.stringify(obj)))
        },
        // 选择销售规模
        xsgmSelect(idx) {
            this.xsgmIdx = idx;
        },
        // 添加销售规模
        addXsgm() {
            this.xsgmList.push({
                wlz: '',
                nxsgm: 0,
                yjmdgm: 0
            })
        },
        // 删除销售规模
        delXsgm() {
            if (this.xsgmIdx.length <= 0) {
                this.$message.warning('请至少选择一项数据!')
                return
            }
            this.xsgmList = this.xsgmList.filter((item, index) => !this.xsgmIdx.includes(index))
            this.xsgmIdx = []
        },
        // 复制销售规模
        copyXsgm() {
            if (this.xsgmIdx.length <= 0) {
                this.$message.warning('请至少选择一项数据!')
                return
            }
            if (this.xsgmIdx.length > 1) {
                this.$message.warning('只能一条数据进行复制!')
                return
            }
            let obj = this.xsgmList[this.xsgmIdx[0]];
            this.xsgmList.push(JSON.parse(JSON.stringify(obj)))
        },
        // 年销售规模输入
        nxsgmInput(n) {
            console.log(n)
        },
        getTotal() {
            let znxsgm = 0;//总年销售规模(万元)：
            let zyjmdgm = 0;//总预计门店规模(万元)：
            for (let item of this.xsgmList) {
                znxsgm += item.nxsgm;
                zyjmdgm += item.yjmdgm;
            }
            this.formData.znxsgm = znxsgm;
            this.formData.zyjmdgm = zyjmdgm;
        },
        accAdd(arg1, arg2) {
            var r1, r2, m;
            try {
              r1 = arg1.toString().split(".")[1].length;
            } catch (e) {
              r1 = 0;
            }
            try {
              r2 = arg2.toString().split(".")[1].length;
            } catch (e) {
              r2 = 0;
            }
            m = Math.pow(10, Math.max(r1, r2));
            return (arg1 * m + arg2 * m) / m;
          },
        // 获取商家列表
        getMerchant(e) {
            let data = {
                searchstr: e
            }
            getMerchant(data).then(res => {
                if (res.data.code == 0) {
                    this.ownedBusiness = res.data.list
                }
            })
        },
        // 所属商家改变
        ownedBusinessChange(e) {
            this.formData.ownedBusiness = this.ownedBusiness[e].code
            // 获取渠道类别，渠道类别细分，集团一级标签，集团二级标签
            getMerchantDetail(this.formData.ownedBusiness).then(res => {
                let merchantDetail = res.data.data;
                this.formData.channelCategory = merchantDetail.primaryChannelText;
                this.formData.channelCategoryId = merchantDetail.primaryChannelId;

                this.formData.channelCategorySub = merchantDetail.secondChannelText;
                this.formData.channelCategorySubId = merchantDetail.secondChannelId;

                this.formData.labelLv1 = merchantDetail.primaryLabelText;
                this.formData.labelLv1Id = merchantDetail.primaryLabelId;

                this.formData.labelLv2 = merchantDetail.secondLabelText;
                this.formData.labelLv2Id = merchantDetail.secondLabelId;
            })
            // 获取办事处
            getBsc(this.formData.ownedBusiness).then(res => {
                this.bscInfos = res.data.list;
            })
            this.wlz = []
            this.marketingCenterBsc = []
            for (let item of this.bscList) {
                item.bsc = ''
                item.bscId = ''
                item.companyId = ''
                item.company = '';
                item.wlz = ''
                item.marketingCenter = ''
            }
            for (let item of this.xsgmList) {
                item.wlz = ''
            }
            for (let item of this.storeDemands) {
                item.wlz = ''
            }
        },
        principalInput(e) {
            let data = {
                searchstr: e
            }
            getPrincipal(data).then(res => {
                if (res.data.code == 0) {
                    this.principal = res.data.list;
                    console.log(res.data.list)
                }
            })
        },
        // 片区负责人改变
        principalChange(e) {
            this.formData.principal = this.principal[e].code;
            getPrincipalDetail(this.formData.principal).then(res => {
                if (res.data.code == 0) {
                    this.formData.principalPhone = res.data.data.loginName
                }
            })
        },
        // 门店需求物料组改变
        storeDemandWlzChange(idx, item) {
            console.log(this.bscWlz[idx])
            // item.wlz = 1111;
            item.bsc = this.bscWlz[idx].bscName
            item.wlz = this.bscWlz[idx].code
        },
        // 办事处改变
        bscChange(idx, item) {
            item.bsc = this.bscInfos[idx].officeId
            item.bscName = this.bscInfos[idx].officeName
            item.bscId = this.bscInfos[idx].officeId
            item.companyId = this.bscInfos[idx].officeId
            item.company = this.bscInfos[idx].orgName
            item.wlz = ''
            item.wlzName = ''
            getWlz(this.customer.id, this.bscInfos[idx].orgId).then(res => {
                this.wlz = res.data.list
            })
            getMarketingCenter(item.bscId).then(res => {
                this.marketingCenterBsc = res.data.list
            })
            for (let item of this.xsgmList) {
                item.wlz = '';
            }
            this.bscWlz = []
        },
        // 选择所属运营商
        operatorInput(str, item) {
            if (!item.bscId) {
                this.$message.info("请选择所属办事处", 2);
                return
            }
            if (!item.wlz) {
                this.$message.info("请选择物料组", 2);
                return
            }
            let data = {
                searchstr: str,
                orgId: item.bscId,
                matklId: item.wlz
            }
            getOperator(data).then(res => {
                this.operator = res.data.list
            })
        },
        operatorChange(id, item) {
            item.operatorId = id
        },
        salesmanForus(e) {
            // console.log(e)
        },
        salesmanInput(e, opt) {
            if (!opt.bscId) {
                this.$message.info("请选择所属办事处", 2);
                return
            }
            let data = {
                searchstr: e,
                officeId: opt.bscId
            }
            getSalesman(data).then(res => {
                this.salesman = res.data.list
            })
        },
        // 营销员改变
        salesmanChange(item, idx) {
            item.salesman = this.salesman[idx].code
            item.salesmanPhone = this.salesman[idx].desc
        },
        // 获取下拉数据
        getParams() {
            getParams().then(res => {
                if (res.data.code == 0) {
                    let data = res.data.dict;
                    this.isCore = data.isCoreShop //是否核心店铺
                    this.isEnter = data.isEnter //是否入住
                    this.cooperationStatus = data.djFlag; //冻结
                    this.isSink = data.isSinkChannel; //是否下沉
                    this.isBooth = data.isSubmitSupplyId //是否做展台
                    this.isTop = data.isTopShop //是否TOP客户加盟店
                    this.marketLevels = data.marketLevelId //市场级别
                    this.storeCategory = data.shopCategory //门店类别
                    this.formData.storeCategory = [data.shopCategory[0].code]
                    this.storefront = data.shopPropertyId; //店面性质
                    this.brand = data.netStandardId //通信品端信联
                    this.isSap = data.isToSap //是否发送SAP
                    this.demandType = data.supplyTypeId //需求类型
                    this.dispatchType = data.isHeaderUnion //派单类型
                    this.cityLv = data.districtType //城市级别
                    this.category = data.branchId //品牌
                    this.storeLevel = data.shopLevelId

                    this.circleType = data.tradingTypeId //商圈类型
                    this.store3Size = data.threeScaleId //门店三大品类年规模
                    this.brandSettled = data.friendBrandId //友商品牌进驻
                    this.isMainFloor = data.isMainFloor //是否主销楼层
                    this.closedBrand = data.closedBrandId // 紧临品牌
                    this.isStandard = data.isUpStandard //是否达标
                }
            })
        },
        // 派单类型改变
        supplierInput(e, item) {
            console.log(item.dispatchType == 2)
            if (item.dispatchType == 2) {
                let data = {
                    searchstr: e
                }
                getSupplier(data).then(res => {
                    this.supplier = res.data.list
                })
            }
        },
        supplierChange(idx, item) {
            item.supplier = this.supplier[idx].code
            item.supplierName = this.supplier[idx].name
        },
        getReason(content, id) {
            if (!id) {
                this.$message.info("请选择办事处经理", 2);
                return
            }
            if (!content) {
                this.$message.info("请输入申请原因", 2);
                return
            }
            this.bscManagerId = id;
            this.content = content;
            this.visible = false;
            this.spinning = true;


            let shopInfoDetailCheckList = [];
            for (let item of this.bscList) {
                shopInfoDetailCheckList.push({
                    "isNewRow": true,
                    "djFlag": "1",
                    "custInfoId": this.formData.ownedBusiness,
                    "orgId": item.companyId,
                    "brandId": item.category,
                    "baseMatklId": item.wlz,
                    "shopLevelId": item.storeLevel,
                    "shopArea": item.area,
                    "modelCount": item.num,
                    "managerName": item.manager,
                    "managerTel": item.managerPhone,
                    "sellerId": item.salesman,
                    "isEnter": item.isEnter,
                    "isTopShop": item.isTop,
                    "runCustTypeId": item.operatorId,
                    shopFloor: item.floor,
                    netStandardId: item.brand,
                    isToSap: item.isSap,
                    terminalOrgId: item.marketingCenter,
                    warehouseId: item.merchantId
                })
            }
            let custShopInfoSalesCheckList = []
            for (let item of this.xsgmList) {
                custShopInfoSalesCheckList.push({
                    isNewRow: true,
                    matklId: item.wlz,
                    annualSales: item.nxsgm,
                    forcastSales: item.yjmdgm
                })
            }
            let supplyDetailList = [];
            for (let item of this.storeDemands) {
                supplyDetailList.push({
                    "baseMatklId": item.wlz,//物料组
                    "enterTime": item.time,//要求完成时间
                    "estimatedCost": item.costEstimate,//费用预估
                    // "finishDate": "2022-03-30T16:00:00.000Z",
                    "isHeaderUnion": item.dispatchType,//派单类型
                    "isNewRow": true,
                    "orgId": item.bsc,//办事处名称
                    "remark": item.illustrate,//备注
                    "supplierCode": item.supplier,//供应商
                    "supplyTypeId": item.demandType,//需求类型
                })
            }

        },
        // 门店全称输入
        storeFullNameSearch(e) {
            let data = {
                searchstr: e,
                type: 1
            }
            getStore(data).then(res => {
                if (res.data.code == 0) {
                    this.storeFullNames = res.data.list;
                }
            })
        },
        storeShortNameSearch(e) {
            let data = {
                searchstr: e,
                type: 2
            }
            getStore(data).then(res => {
                if (res.data.code == 0) {
                    this.storeShortNames = res.data.list;
                }
            })
        },
        save() {
            // this.visible = true;
            // return
            if (this.formData.isBooth == 14923253081 && !this.formData.projectRequirements) {
                this.$message.info("请输入项目需求名称", 2);
                return
            }
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    this.spinning = true;


                    let shopInfoDetailCheckList = [];
                    for (let item of this.bscList) {
                        shopInfoDetailCheckList.push({
                            "isNewRow": this.type == 'add' ? true : item.isNewRow,
                            id: item.id || '',
                            "djFlag": "1",
                            "custInfoId": this.formData.ownedBusiness,
                            "orgId": item.companyId,
                            "brandId": item.category,
                            "baseMatklId": item.wlz,
                            "shopLevelId": item.storeLevel,
                            "shopArea": item.area,
                            "modelCount": item.num,
                            "managerName": item.manager,
                            "managerTel": item.managerPhone,
                            "sellerId": item.salesman,
                            "isEnter": item.isEnter,
                            "isTopShop": item.isTop,
                            "runCustTypeId": item.operatorId,
                            shopFloor: item.floor,
                            netStandardId: item.brand,
                            isToSap: item.isSap,
                            terminalOrgId: item.marketingCenter,
                            warehouseId: item.merchantId,
                            djVouchers: item.file
                        })
                    }
                    let custShopInfoSalesCheckList = []
                    for (let item of this.xsgmList) {
                        custShopInfoSalesCheckList.push({
                            "isNewRow": this.type == 'add' ? true : item.isNewRow,
                            id: item.id || '',
                            matklId: item.wlz,
                            annualSales: item.nxsgm,
                            forcastSales: item.yjmdgm
                        })
                    }
                    let supplyDetailList = [];
                    for (let item of this.storeDemands) {
                        supplyDetailList.push({
                            id: item.id || '',
                            "baseMatklId": item.wlz,//物料组
                            "enterTime": item.time,//要求完成时间
                            "estimatedCost": item.costEstimate,//费用预估
                            // "finishDate": "2022-03-30T16:00:00.000Z",
                            "isHeaderUnion": item.dispatchType,//派单类型
                            "isNewRow": this.type == 'add' ? true : item.isNewRow,
                            "orgId": item.bsc,//办事处名称
                            "remark": item.illustrate,//备注
                            "supplierCode": item.supplier,//供应商
                            supplierName: item.supplierName,
                            "supplyTypeId": item.demandType,//需求类型
                        })
                    }
                    let data = {
                        "id": this.type == 'add' ? 0 : this.$route.query.id,//审批id,0表示没有
                        "attachs": [],//附件id
                        // "longitude": this.center.lng,
                        // "latitude": this.center.lat,
                        "deleteDetailList": [],


                        "custInfoId": this.formData.ownedBusiness,//商家id
                        "fullName": this.formData.storeFullName,//门店名称
                        "searchTerm": this.formData.storeShortName,//门店简称
                        "marketLevelId": this.formData.marketLevel,//市场级别
                        "provinceId": this.formData.province,//省
                        "cityId": this.formData.city,//市
                        "countyId": this.formData.area,//县/区
                        "townId": this.formData.street,//镇
                        "shAddress": "山东省青岛市市南区湛山街道镇江南路海信学校2",//地址
                        addressDesc: this.formData.addrDescribe, //地址描述
                        "longitude": 120.372273,
                        "latitude": 36.070659,
                        "postalCode": this.formData.postCode,//邮编 
                        "districtType": this.formData.cityLv,//城市级别
                        "marketCenterId": this.formData.marketingCenterId,//营销中心
                        "shopPropertyId": this.formData.storefront,//店面性质
                        "isSinkChannel": this.formData.isSink,//是否下沉门店1是，0否
                        "shopCategory": this.formData.storeCategory,//门店类别
                        "primaryChannelId": this.formData.channelCategoryId,//商家-渠道
                        "secondChannelId": this.formData.channelCategorySubId,//商家-渠道明细
                        "primaryLabelId": this.formData.labelLv1Id,// 集团一级标签ID
                        "secondLabelId": this.formData.labelLv2Id,//集团二级渠道ID
                        "source": "XTW",//来源
                        "isSubmitSupplyId": this.formData.isBooth,
                        projectSupplyName: this.formData.projectRequirements,
                        projectPlanId: this.formData.isBooth != 14923253081 ? '' : 14182428490,
                        "isCoreShop": this.formData.isCore,
                        "areaLeaderId": this.formData.principal,
                        "totalAnnual": this.formData.znxsgm,
                        "totalForcast": this.formData.zyjmdgm,
                        "bscManagerId": this.bscManagerId,
                        "content": this.formData.content,


                        "shopInfoDetailCheckList": shopInfoDetailCheckList,
                        "custShopInfoSalesCheckList": custShopInfoSalesCheckList,
                        supplyDetailList
                    }
                    save(data).then(res => {
                        console.log(res)
                        this.spinning = false;
                        if (res.data.code == 0) {
                            this.$message.success("提交成功", 2);
                            setTimeout(() => {
                                window.close();
                            }, 2000);
                        } else {
                            this.$message.info(res.data.msg, 2);
                        }
                    })


                } else {
                    this.$message.info("请将信息填写完整", 2);
                }
            });
        },
        // 是否做展台变更
        isBoothChange(e) {
            if (e != 14923253081) {
                this.formData.projectRequirements = ''
            }
        },
        // 办事处物料组变更
        wlzChange(code, item) {
            for (let it of this.wlz) {
                if (code == it.code) {
                    item.wlzName = it.name
                    item.wlz = it.code
                    break
                }
            }
            console.log(this.bscList)
            let bscWlz = []
            for (let item of this.bscList) {
                bscWlz.push({
                    bsc: item.bscId,
                    bscName: item.bscName,
                    code: item.wlz,
                    name: item.wlzName
                })
            }
            this.bscWlz = bscWlz
        },
        upload(info, idx) {
            if (info.file.status === "done") {
                if (info.file.response.message == "上传成功") {
                    this.bscList[idx].file = [{
                        name: info.file.name,
                        id: info.file.response.businessId
                    }]
                } else {
                    this.$message.warning("上传失败");
                }
            }

        }
    },
};
